import { Component, OnInit, Inject } from '@angular/core';
import { ApiService } from '../../services/ApiService.class';
import { HttpClient } from '@angular/common/http';
import { Computer, SlmObject, ObjectModel } from "../../classes/classes";
import { DataService } from "../../services/data.service";

@Component({
	selector: 'app-assetlist',
	templateUrl: './assetlist.component.html',
	providers: [ApiService],
	styleUrls: ['./assetlist.component.css']
})
export class AssetlistComponent implements OnInit {

	assets: Array<any>;
	_http: HttpClient;
	_baseUrl: string;
	cid: string;
	test: Array<any>;
	currentcomputer: any;
	self: this;

	searching = false;
	
	constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private _service: ApiService, private dataService: DataService) {
		this._http = http;
		this._baseUrl = baseUrl;
		this.dataService.currentCustomerId.subscribe(cid => this.cid = cid);
		this.currentcomputer = new Computer();
	}
	/*
	constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private apiService: ApiService,
		private zone: NgZone, ref: ChangeDetectorRef) {
		this._http = http;
		this._baseUrl = baseUrl;
		this.currentcomputer = new Computer();
		this.refDetect = ref;

		window['barcodeValidate'] = {
			zone: this.zone,
			componentFn: (value: string) => this.getAsset(value),
			component: this,
		};
	}*/

	ngOnInit() {
		this.getAssetList();
	}

	getSlmComputer(serialnumber: string) {
		this._service.getSelfComputer(serialnumber).subscribe(
			r => {
				this.currentcomputer = r
			});
	}

	private showSpinner(show: boolean) {
		this.searching = show;
		var btn = document.getElementById("searchButton");
		btn.style.display = !show ? "block" : "none";
	}

	getAssetList() {
		this.showSpinner(true);
		this._service.searchSerial('ALL').subscribe(res => {
			this.assets = res; // Do not touch
			this.showSpinner(false);
		});
	}
 
}
