import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/ApiService.class';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../app.config';
import { NavbarComponent } from '../../nav/navbar/navbar.component';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';

import { Html5Qrcode } from "html5-qrcode";
import { Computer, ObjectModel, SlmObject } from '../../classes/classes';
import { SessionService } from '../../services/session.service';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';

@Component({
	selector: 'app-assetswap',
	providers: [ApiService],
	templateUrl: './assetswap.component.html',
	styleUrls: ['./assetswap.component.css']
})
export class AssetSwapComponent implements OnInit {

	assets: any;

	selectedField = null;

	searchString = null;
	searching = false;

	searchInput: any;
	serialInput: any;

	oldComputerModel = new ObjectModel();
	newComputerModel = new ObjectModel();

	// Camera related variables
	cameraId;
	scanning: boolean;
	html5QrCode;
	availableCameras;
	selectedCamera = null;
	scanField: string;
	cameraChosen: boolean = false;
	cameraRunning: boolean = false;
	lookup;

	validateSearchError = false;
	validateOrderedFor = false;
	validateAddressCity = false;
	validateAddressZip = false;
	validateAddressStreet = false;

	searchCurrentComputer = false;

	singleSelect: any = true;
	bulkSelect: any = false;

	ua = navigator.userAgent;
	deviceType: string = "";

	refDetect: ChangeDetectorRef;

	_http: HttpClient;
	_baseUrl: string;
	currentCustomer: any;
	manufacturer: string;
	model: string;
	sitename: string;
	searchFailed = false;
	enteredField: any;
	enteredString: any;
	currentcomputer: any = null;
    lifeCycleError: string;
    opStateError: string;
    orderedFor: null;
	deliveryCity: null;
	deliveryZip: null;
	deliveryStreet: null;
	postedValues: boolean;
	newComputer: any = null;
	searchActive: boolean = false;
	allFieldsOk: boolean = false;
	sessionService: SessionService;	
	retiredAsset: boolean = false;
	searchRetired: boolean = false;

	constructor(private apiService: ApiService, public modalService: NgbModal) {
		this.currentcomputer = new Computer();
		this.sessionService = new SessionService();
		this.currentCustomer = NavbarComponent.currentCustomer;
	}

	ngOnInit() {
		this.isMobile();
		this.getCameras();
	}

	formatter = (result: any) => result.objectname;
	computerformatter = (result: any) => result.biosSerialNumber;
	computerNameFormatter = (result: any) => result.Name;

	// Swap search
	// This function runs whenever the user types in the search field and searches for serial of the new device.
	searchSerial = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			tap(() => {
				this.searchActive = false;
				this.allFieldsOk = false;
				this.searchCurrentComputer = false;
				this.searchRetired = false;
				this.newComputerModel = new ObjectModel();
				this.searchCurrentComputer = false;
				this.retiredAsset = false;
				this.searchFailed = false;
			}),
			switchMap(term =>
				this.apiService.searchSerial(String(term)).pipe(
					tap(result => {
						if (result.length == 0) {
							this.searchFailed = true;
						}
					}),
					catchError(() => {
						this.searchFailed = true;
						return of([]);
					}))
			), 
			tap(() => this.searching = false),
		);

	// This function runs whenever the user selects an asset from the drop-down menu that searchSerial generates.
	selectedItem(item) {
		this.newComputer = item.item;
		//this.newComputer.biosSerialNumber = item.item.biosSerialNumber;
		if (this.newComputer.biosSerialNumber == this.currentcomputer.biosSerialNumber) {
			//alert("You cannot swap a device with itself.");
			this.searchCurrentComputer = true;
			this.searchRetired = false;
			return;
		}
		this.generateNewModel(this.newComputerModel);
	}

	// Old version of selectedItem, this gets the entire object from the search and not just the serial number.
	// Used to check if the asset is retired or not, but is too slow.
	/* selectedItem(item) {
		//this.cid = this.apiService.cid;
		 this.apiService.getSelfComputer(item.item).subscribe(res => {
			this.newComputer = res;
			if (this.newComputer.biosSerialNumber == this.currentcomputer.biosSerialNumber) {
				//alert("You cannot swap a device with itself.");
				this.searchCurrentComputer = true;
				this.searchRetired = false;
				return;
			}
			else if (this.newComputer.lifeCycleState == "Retired") {
				this.searchRetired = true;
				this.searchCurrentComputer = false;
				return;
			}
			else {
				this.searchCurrentComputer = false;
				this.searchRetired = false;
			}

		}, error => {
		}, () => {
			this.searchActive = true;

			setTimeout(() => {
				this.generateNewModel(this.newComputerModel);
			}, 300);
			
		});
	} */

	isMobile() {
		//let check = false;
		//(function (a) {
		//	if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
		//		check = true;
		//})(navigator.userAgent || navigator.vendor || window.opera);
		//return check;


		if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(this.ua)) {
			return "tablet";
		}
		if (
			/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
				this.ua
			)
		) {
			this.deviceType = "mobile";
		}
		this.deviceType = "desktop";
	};

	getCameras() {
		Html5Qrcode.getCameras().then(devices => {
			if (devices && devices.length) {
				if (devices.length < 2) {
					this.cameraId = devices[0].id;
				}
				this.availableCameras = devices;
			}
		}).catch(err => {
			// handle err
		});
	}

	prepareScanning(field: string) {
		this.scanning = true;
		this.scanField = field;
		if (this.availableCameras.length < 2) {
			this.cameraChosen = true;
			this.startScanning();
		}
		else if (this.html5QrCode) {
			this.startScanning();
		}
	}

	cameraSelected(camera: any) {
		this.cameraChosen = true;
		try {
			if (this.availableCameras.length > 1 && this.html5QrCode.isScanning === true) {
				this.swapCamera();
				this.cameraId = camera;
				this.startScanning();
			}
			else {
				this.cameraId = camera;
				this.startScanning();
			}
		}
		catch {
			this.cameraId = camera;
			this.startScanning();
		}
	}

	startScanning() {
		this.cameraRunning = true;
		this.html5QrCode = new Html5Qrcode(/* element id */ "reader");
		//this.lookup = document.getElementById("lookup");
		this.searchInput = document.getElementById("searchString");
		this.serialInput = document.getElementById("newComputer");
		const config = {
			fps: 10,
			qrbox: { width: 250, height: 250 },
			rememberLastUsedCamera: true
			// Only support camera scan type.
		};
		// If you want to prefer back camera

		const qrCodeSuccessCallback = (decodedText, decodedResult) => {
			if (this.scanField == "searchString") {
				this.scanning = false;
				this.searchInput.value = decodedText;
				this.searchInput.dispatchEvent(new Event('input'));
				this.searchInput.focus();
			} else if (this.scanField == "newComputer") {
				this.scanning = false;
				this.serialInput.value = decodedText;
				this.serialInput.dispatchEvent(new Event('input'));
				this.serialInput.focus();
			}

			this.html5QrCode.stop().then((ignore) => {
				// QR Code scanning is stopped.
			}).catch((err) => {
				// Stop failed, handle it.
			});
		}

		if (this.deviceType == "mobile") {
			this.html5QrCode.start({ facingMode: "environment" });
		}
		else if (this.deviceType == "desktop") {
			this.html5QrCode.start({ deviceId: { exact: this.cameraId } }, config, qrCodeSuccessCallback);
		}

	}

	swapCamera() {
		try {
			if (this.html5QrCode.isScanning === true) {
				this.cameraRunning = false;
				this.html5QrCode.stop().then((ignore) => {
					// QR Code scanning is stopped.
				}).catch((err) => {
					// Stop failed, handle it.
				});
			}
		}
		catch (err) {
			this.cameraRunning = false;
		}
	}

	cancelScan() {
		//const html5QrCode = new Html5Qrcode(/* element id */ "reader");
		try {
			if (this.html5QrCode.isScanning === true) {
				this.scanning = false;
				this.cameraRunning = false;
				this.html5QrCode.stop().then((ignore) => {
					// QR Code scanning is stopped.
				}).catch((err) => {
					// Stop failed, handle it.
				});
			}
			else {
				this.scanning = false;
				this.cameraRunning = false;
			}
		}
		catch (err) {
			this.cameraRunning = false;
		}
	}

	//removeMenu() {
	//	SidebarComponent.prototype.removeMenu();
	//}

	// Swap search
	// Initial search function
	searchForSerial() {
		if (this.searchString == null || this.searchString == "") {
			alert("Please enter a serial number and try again.");
			return;
		}
		var term = this.searchString + "|,,";

		this.showSpinner(true);

		this.apiService.swapLog('info', 'Search for old serial started', this.currentCustomer, this.sessionService.getUserName(), this.searchString, new Date().toISOString());
		let startTime = new Date(); // Start the timer

		this.apiService.getSerialNumbers('Serial Number', term).subscribe(res => {
			this.assets = res;
			this.showSpinner(false);

			this.assets.forEach(element => {
				element.customFields.forEach(field => {
					if (field.name.toLowerCase() == "delivery address - city") {
						element.deliveryCity = field.value;
					} else if (field.name.toLowerCase() == "delivery address - zip") {
						element.deliveryZip = field.value;
					} else if (field.name.toLowerCase() == "delivery address - street") {
						element.deliveryStreet = field.value;
					}
				});
			});

			let endTime = new Date(); // Stop the timer
			let elapsedTime = endTime.getTime() - startTime.getTime(); // Calculate the elapsed time in milliseconds
			console.log(`getSerialNumbers completed in ${elapsedTime} milliseconds`);

			if (elapsedTime > 1000) {
				this.apiService.swapLog('info', `Search for old serial is complete, it took a long time: ${elapsedTime}ms`, this.currentCustomer, this.sessionService.getUserName(), this.searchString, new Date().toISOString());
			} else {
				this.apiService.swapLog('info', 'Search for old serial complete', this.currentCustomer, this.sessionService.getUserName(), this.searchString, new Date().toISOString());
			}
		});

	}

	private showSpinner(show: boolean) {
		this.searching = show;
		let btn = document.getElementById("searchButton");
		btn.style.display = !show ? "block" : "none";
	}
	

	checkValue(isChecked: boolean, txtBoxId: string, selectId: string) {
		let txtBox = document.getElementById(txtBoxId);
		txtBox.className = isChecked ? "form-control bg-white border-warning" : "form-control bg-light";

		let selectBox = document.getElementById(selectId);
		selectBox.className = isChecked ? "form-control bg-warning" : "form-control bg-light";

	}

	// Removes any special characters in order to sanitize input.
	validateInput(fieldInput, element) {
		let temp = fieldInput;
		fieldInput = fieldInput.replace(/[^\d\s\p{Ll},.\\()/-]/giu, " "); // Regex pattern that matches any letter from a-z, nordic characters and digits 0-9
		//fieldInput = fieldInput.replace(/[^\d\s\p{Ll}]/giu, ""); // Regex pattern that matches any letter from a-z, nordic characters and digits 0-9
		let myInput: any = document.querySelector('input[name="'+element+'"]');
		myInput.value = fieldInput;
		
		switch (element)
		{
			case 'searchString':
				this.searchString = fieldInput;
				if (temp != fieldInput) {
					this.validateSearchError = true;
				}
				else {
					this.validateSearchError = false;
				}
				break;

			/* case 'newComputer':
				this.manufacturer = fieldInput;
				if (temp != fieldInput) {
					this.validateSerialError = true;
				}
				else {
					this.validateSerialError = false;
				}
				break;
 			*/
			case 'orderedFor':
				this.orderedFor = fieldInput;
				if (temp != fieldInput) {
					this.validateOrderedFor = true;
				}
				else {
					this.validateOrderedFor = false;
				}
				break;

			case 'deliveryCity':
				this.deliveryCity = fieldInput;
				if (temp != fieldInput) {
					this.validateAddressCity = true;
				}
				else {
					this.validateAddressCity = false;
				}
				break;

			case 'deliveryZip':
				this.deliveryZip = fieldInput;
				if (temp != fieldInput) {
					this.validateAddressZip = true;
				}
				else {
					this.validateAddressZip = false;
				}
				break;

			case 'deliveryStreet':
				this.deliveryStreet = fieldInput;
				if (temp != fieldInput) {
					this.validateAddressStreet = true;
				}
				else {
					this.validateAddressStreet = false;
				}
				break;
		
		}
		this.generateNewModel(this.newComputerModel);
	}

    generateOldModel(object:ObjectModel) {
		try {
			//let objModel = new ObjectModel();

			this.currentCustomer = NavbarComponent.currentCustomer;

			object.CsvFolder = AppConfig.settings.apiSettings.fileShare;
			object.Serialnumber = this.currentcomputer.biosSerialNumber;
			
			object.CurrentComputer = this.currentcomputer == null ? new Computer() : this.currentcomputer;

            object.LifeCycleState = "In Use";
            object.OperationalState = "In transit from customer";

			object.SimObject = new SlmObject();

			//object.SimObject = { objectid: "", objecttypeid: "", objectname: "", organization: "", properties: [] };

			object.UpdatedBy = this.sessionService.getUserName();

		} catch (e) {
			alert(e);
			console.log(e);
		}
	}

    generateNewModel(object:ObjectModel) {
		try {
			this.currentCustomer = NavbarComponent.currentCustomer;

			object.CsvFolder = AppConfig.settings.apiSettings.fileShare;
			object.CurrentComputer = this.newComputer == null ? new Computer() : this.newComputer;
			
			object.SimObject = new SlmObject();
			
			object.Serialnumber = this.newComputer.biosSerialNumber;

            object.LifeCycleState = "In Use";
            object.OperationalState = "Shipped to Customer";
			object.SimObject.OrderedFor = this.orderedFor;
			object.SimObject.City = this.deliveryCity;
			object.SimObject.PostalCode = this.deliveryZip;
			object.SimObject.StreetAddress = this.deliveryStreet;

			object.UpdatedBy = this.sessionService.getUserName();

			if (object.Serialnumber &&
				object.Serialnumber != "" &&
				object.Serialnumber != " " &&
				object.SimObject.OrderedFor &&
				object.SimObject.OrderedFor != "" &&
				object.SimObject.OrderedFor != " " &&
				object.SimObject.City &&
				object.SimObject.City != "" &&
				object.SimObject.City != " " &&
				object.SimObject.PostalCode &&
				object.SimObject.PostalCode != "" &&
				object.SimObject.PostalCode != " " &&
				object.SimObject.StreetAddress &&
				object.SimObject.StreetAddress != "" &&
				object.SimObject.StreetAddress != " " &&
				this.searchCurrentComputer == false &&
				this.searchRetired == false
			) {
				this.allFieldsOk = true;
			}
			else {
				this.allFieldsOk = false;
			}

		} catch (e) {
			alert(e);
			console.log(e);
		}
	}

	private postToServer(oldAsset:any, newAsset:any) {
		this.apiService.postSwapOld(oldAsset);
		this.apiService.postSwapNew(newAsset);
	}

	private openModal(asset: any) {
		this.cancelPost();

		this.currentcomputer = asset;
		this.newComputer = new Computer();
		this.generateOldModel(this.oldComputerModel);

		if (this.currentcomputer.lifeCycleState == "Retired") {
			this.openRetired(asset);
			return;
		}
		this.orderedFor = asset.orderedFor;
		if (this.orderedFor) {
			let temp:any = this.orderedFor;
			this.orderedFor = temp.replace(/[^\d\s\p{Ll},.\\()/-]/giu, " ");
			//this.validateInput(this.orderedFor, 'orderedFor');
		}

		asset.customFields.forEach(element => {
			if (element.name.toLowerCase() == "delivery address - city") {
				this.deliveryCity = element.value;
				if (this.deliveryCity) {
					let temp:any = this.deliveryCity;
					this.deliveryCity = temp.replace(/[^\d\s\p{Ll},.\\()/-]/giu, " ");
					//this.validateInput(this.deliveryCity, 'deliveryCity');
				}
			} else if (element.name.toLowerCase() == "delivery address - zip") {
				this.deliveryZip = element.value;
				if (this.deliveryZip) {
					let temp:any = this.deliveryZip;
					this.deliveryZip = temp.replace(/[^\d\s\p{Ll},.\\()/-]/giu, " ");
					//this.validateInput(this.deliveryZip, 'deliveryZip');
				}
			} else if (element.name.toLowerCase() == "delivery address - street") {
				this.deliveryStreet = element.value;
				if (this.deliveryStreet) {
					let temp:any = this.deliveryStreet;
					this.deliveryStreet = temp.replace(/[^\d\s\p{Ll},.\\()/-]/giu, " ");
					//this.validateInput(this.deliveryStreet, 'deliveryStreet');
				}
			}
		});

		this.postedValues = true;
	}

	private openRetired(asset: any) {
		this.retiredAsset = true;
	}
	
	private cancelPost() {
		this.postedValues = false;
		this.searchActive = false;
		this.oldComputerModel = new ObjectModel();
		this.newComputerModel = new ObjectModel();
		this.currentcomputer = new Computer();
		this.newComputer = new Computer();
		this.orderedFor = null;
		this.deliveryCity = null;
		this.deliveryZip = null;
		this.deliveryStreet = null;
		this.validateSearchError = false;
		this.validateOrderedFor = false;
		this.validateAddressCity = false;
		this.validateAddressZip = false;
		this.validateAddressStreet = false;
		this.searchCurrentComputer = false;
		this.allFieldsOk = false;
		this.retiredAsset = false;
		this.searchRetired = false;
	}

}