import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/ApiService.class';
import { Computer } from "../../classes/classes";
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

@Component({
	selector: 'app-assetportal',
	templateUrl: './assetportal.component.html',
	providers: [ApiService],
	styleUrls: ['../../sitelayout/sitelayout.component.css']
})
export class AssetPortalComponent implements OnInit {

	currentAsset: any;
	config: any;
	serial: any;
	cid: any;
	isLoaded: boolean;

	constructor(private activatedRoute: ActivatedRoute, private router: Router, private apiService: ApiService) {
		//this.currentAsset = new Computer();
		this.isLoaded = false;
	}

	// TODO: Make sure that the CID is 20 (Systembolaget) before fetching the serial number, as this can expose any device in the database.
	ngOnInit(): void {
		if (this.cid == "20" || this.cid == "1") {
			this.activatedRoute.params.subscribe(paramsId => {
				this.cid = paramsId.cid;
				this.serial = paramsId.serial;
				this.apiService.getPortalSerial(this.cid, this.serial).subscribe(res => {
					this.currentAsset = res;
					//console.log(res);
					//this.currentAsset = 'test';
					this.isLoaded = true;
				});
				this.apiService.getPortalConfig().subscribe(res => {
					this.config = res;
				});
				
			});

			this.info();
		} else {
			this.router.navigateByUrl('search');
			return;
		}
	}

	info() {
		//var regEx = new RegExp("/^(https ?):\/\/samportal.evry.com\/assetportal\/" + this.cid + "\//gm");
		//console.log("cid: ", this.cid);
		
		var serial = document.getElementById("label-serial");
		var name = document.getElementById("label-name");
		var manufacturer = document.getElementById("label-manufacturer");
		var model = document.getElementById("label-model");
		var email = document.getElementById("label-email");
		var phone = document.getElementById("label-phone");

		// This if statement checks to see if the CID in the URL equals 20 (Systembolaget), and replaces the text in the various fields with the equivalent text in Swedish.
		if (this.cid == "20") {
			serial.innerHTML = "SERIENUMMER";
			name.innerHTML = "DATORNAMN";
			manufacturer.innerHTML = "TILLVERKARE";
			model.innerHTML = "MODELL";
			email.innerHTML = "Registrera en incident i ServicePortalen";
			phone.innerHTML = "Är ditt ärende akut, ring Servicedesk på 08-50332222"; 
		}
		/*if (this.cid == "1") {
			serial.innerHTML = "SEEEERI";
			name.innerHTML = "Computername";
			manufacturer.innerHTML = "Produsent";
			model.innerHTML = "Modell";
			email.innerHTML = "Trykk her, yo!";
			phone.innerHTML = "Är ditt ärende akut, ring Servicedesk på 08-50332222";
		}*/

	}

}