import { Injectable } from '@angular/core';
const TOKEN = 'TOKEN';
const REFRESHTOKEN = 'REFRESHTOKEN';    
const CUSTOMERS = 'CUSTOMERS';
const CID = 'CID';
const CREDENTIALS = 'CREDENTIALS';
const STATECONFIG = 'STATECONFIG';
const USERNAME = 'USERNAME';
const USERROLE = 'USERROLE'
const LASTREFRESH = 'LASTREFRESH';


/** This service provides functions for setting and getting the user's credentials, token, refresh token, username, customers, customer ID, customer state configuration, user role, and last refresh time. */
@Injectable({
    providedIn: 'root'
})
export class SessionService {
    /** Sets the user's credentials in session storage. */
    setCredentials(username: string, password: string) {
        sessionStorage.setItem(CREDENTIALS, btoa(username + ':' + password));
    }

    /** Sets the user's token in session storage. */
    setToken(token: string): void {
        sessionStorage.setItem(TOKEN, token);
    }

    /** Sets the user's username in session storage. */
    setUserName(username: string): void {
        sessionStorage.setItem("USERNAME", username);
    }

    /** Gets the user's username from session storage. */
    getUserName() {
        return sessionStorage.getItem(USERNAME);
    }

    /** Gets the user's token from session storage. */
    getToken() {
        return sessionStorage.getItem(TOKEN);
    }

    /** Sets the user's refresh token in session storage. */
    setRefreshToken(refreshToken: string): void {
        sessionStorage.setItem(REFRESHTOKEN, refreshToken);
    }

    /** Gets the user's refresh token from session storage. */
    getRefreshToken() {
        return sessionStorage.getItem(REFRESHTOKEN);
    }

    /** Sets the user's customers in session storage. */
    setCustomers(customers: any) {
        sessionStorage.removeItem(CUSTOMERS);
        sessionStorage.setItem(CUSTOMERS, JSON.stringify(customers));
    }

    /** Gets the user's customers from session storage. */
    getCustomers(): any {
        return JSON.parse(sessionStorage.getItem(CUSTOMERS));
    }

    /** Clears all session storage. */
    clearAll(): void {
        sessionStorage.clear();
    }

    /** Checks if the user is logged in. */
    isLoggedIn() {
        return sessionStorage.getItem(TOKEN) != null;
    }

    /** Sets the user's customer ID in session storage. */
    setCustomerId(customerid: string): void {
        sessionStorage.setItem(CID, customerid);
    }

    /** Gets the user's customer ID from session storage. */
    getCustomerId() {
        return sessionStorage.getItem(CID);
    }

    /** Gets the user's credentials from session storage. */
    getCredentials() {
        return sessionStorage.getItem(CREDENTIALS);
    }

    /** Sets the user's customer state configuration in session storage. */
    setCustomerStateConfig(stateConfig: any) {
        sessionStorage.setItem(STATECONFIG, JSON.stringify(stateConfig));
    }

    /** Gets the user's customer state configuration from session storage. */  
    async getCustomerStateConfig() {
        return await Promise.resolve(sessionStorage.getItem(STATECONFIG));
    }

    /** Sets the user's role in session storage. */
    setUserRole(role = 'default') {
        sessionStorage.setItem(USERROLE, role);
    }

    /** Gets the user's role from session storage. */
    getUserRole() {
        return sessionStorage.getItem(USERROLE);
    }

    /** Sets the last refresh time in session storage. */
    setLastRefresh() {
        sessionStorage.setItem(LASTREFRESH, new Date().toString());
    }

    /** Gets the last refresh time from session storage. */
    getLastRefresh() {
        return sessionStorage.getItem(LASTREFRESH);
    }

 }
