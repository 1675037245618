import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse } from "@angular/common/http";
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, filter, take, } from 'rxjs/operators';
import { BehaviorSubject } from "rxjs";
import { SessionService } from './session.service';
import { AuthenticationService } from './authentication.service';
import { switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

    isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(public session: SessionService, public auth: AuthenticationService, private router: Router) { }

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } })
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        if (request.url.includes("login")) return next.handle(request);
        return next.handle(this.addToken(request, this.session.getToken())).pipe(catchError(error => {
            if (error instanceof HttpErrorResponse) {
                var status = (<HttpErrorResponse>error).status;
                if (status === 401) {
                    return this.handle401Error(request, next);
                }
                if (status === 400) {
                    return this.logoutUser();
                }

            }
            else {
                return Observable.throw(error);
            }

        }))
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            return this.auth.refreshAccessToken().pipe(
                switchMap((newToken: any) => {
                    if (newToken) {
                        this.tokenSubject.next(newToken);
                        this.session.setToken(newToken.token);
                        this.session.setRefreshToken(newToken.refreshToken);
                        return next.handle(this.addToken(req, newToken.token));
                    }

                    // If we don't get a new token, we are in trouble so logout.
                    return this.logoutUser();
                }),
                catchError(() => {
                    // If there is an exception calling 'refreshToken', bad news so logout.
                    return this.logoutUser();
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                })

            )
        } else {
            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addToken(req, token));
                }));
        }
    }


    logoutUser() {
        this.session.clearAll();
        this.router.navigateByUrl('login');
        return Observable.throw("");
    }
}