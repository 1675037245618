import { Component } from '@angular/core';
import { ApiService } from "../services/ApiService.class";
import { Router } from '@angular/router';
import { SessionService } from '../services/session.service';
import { DataService } from "../services/data.service";


@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['../sitelayout/sitelayout.component.css']
})
export class LoginPageComponent {

    username = '';
    password = '';

    //sess: SessionService;

    /**
     * Constructor for the LoginPageComponent.
     * @param api Service for making API calls.
     * @param router Service for navigating between pages.
     * @param session Service for managing the user's session.
     * @param dataService Service for managing the data.
     */
    constructor(private api: ApiService, private router: Router, private session: SessionService, private dataService: DataService) {
        //this.sess = session;
    }

    /** 
     * Attempts to log in the user.
     * 
     * This function sends a login request to the API with the provided username and password.
     * 
     * If the login is successful, it performs various actions based on the response, such as setting the user's token, refresh token, username, customers, and user role.
     * 
     * Finally, it navigates to the 'search' page.
     * If the login fails, it displays an alert with the error message.
     */
    tryLogin() {
        this.api.login(
            this.username,
            this.password
        )
            .subscribe(
                /** @param r - The response from the login API call. */
                r => {
                    // If the response contains a token, it sets the user's token, refresh token, username, customers, and user role based on the response data.
                    if (r.token) {
                        this.session.setToken(r.token);
                        this.session.setRefreshToken(r.refreshtoken);
                        this.session.setUserName(this.username);
                        this.dataService.setCustomers(r.customers);
                        this.session.setLastRefresh();

                        // If the number of customers is 2 and both have IDs 1 and 2, it removes the customer with ID 1 from the list and sets the first customer's ID as the selected customer ID.
                        if (r.customers.length == 2 && r.customers.find(o => o.id == 1) && r.customers.find(o => o.id == 2)) {
                            let toRemove = r.customers.find(o => o.id == 1);
                            r.customers.splice(r.customers.indexOf(toRemove), 1);
                            let custId = r.customers[0].id;
                            this.dataService.setCustomers(r.customers);
                            this.session.setUserRole('customerservice');
                            this.dataService.setCustomerId(custId); 
                        }
                        // If the number of customers is greater than 2 and the first customer has ID 1, it sets the user role as 'admin' and sets the first customer's ID as the selected customer ID.
                        else if (r.customers.length > 2 && r.customers[0].id == 1) {
                            this.session.setUserRole('admin');
                            this.dataService.setCustomerId(r.customers[0].id);
                        }
                        // Otherwise, it sets the first customer's ID as the selected customer ID and sets the user role as 'default'.
                        else {
                            this.dataService.setCustomerId(r.customers[0].id);
                            this.session.setUserRole('default');
                        }
                        this.router.navigateByUrl('search');
                    } else {
                        // If the response does not contain a token, display an alert with the error message.
                        alert(r.error);
                    }
                },
                /**
                 * Handles the error from the login API call.
                 * 
                 * Displays an alert with the status text of the error.
                 * 
                 * @param r - The error response from the login API call.
                 */
                r => {
                    alert(r.statusText);
                });
    }

}
