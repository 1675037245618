import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/ApiService.class';
import { ModalContentComponent } from '../modal-content/modal-content.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
	selector: 'app-assetfreesearch',
	providers: [ApiService],
	templateUrl: './assetfreesearch.component.html',
	styleUrls: ['./assetfreesearch.component.css']
})
export class AssetFreeSearchComponent implements OnInit {

	assets: any;

	/*Copy of original function*/
	validSearchFields = [
		{ id: 0, name: "Name" },
		{ id: 1, name: "Serial Number" },
		{ id: 2, name: "Manufacturer" },
		{ id: 3, name: "Model" },
		{ id: 4, name: "User" },
		{ id: 5, name: "Customer Asset Name", value: "customer asset name" }/*,
		{ id: 6, name: "EVRY Finance status", value: "evry finance status" },
		{ id: 7, name: "EVRY Finance agreement", value: "evry finance agreement" },
		{ id: 8, name: "Lifecycle state", value: "lifecyclestate" },
		{ id: 9, name: "Operational state", value: "operationalstate" },
		{ id: 10, name: "Ordered by", value: "ordered by" },
		{ id: 11, name: "Ordered for", value: "ordered for" },
		{ id: 12, name: "Product number", value: "product no" },
		{ id: 13, name: "Service offering", value: "service-offering" },
		{ id: 14, name: "Start invoicing", value: "start invoicing" },
		{ id: 15, name: "End invoicing", value: "end invoicing" },
		{ id: 16, name: "Stock location", value: "stocklocation" },
		{ id: 17, name: "Warranty end date", value: "warranty end date" }*/
	];

	selectedField = null;
	selectedCustomField = null;

	searchString = null;
	searching = false;

	customFieldSearch = false;

	selectedIsServer = "Yes";
	selectedIsPortable = "Yes";
	selectedIsVirtual = "Yes";

	constructor(private apiService: ApiService, public modalService: NgbModal) {
	}

	ngOnInit() {
	}

	//removeMenu() {
	//	SidebarComponent.prototype.removeMenu();
	//}

	searchForSerial() {

		if (this.selectedField == null || this.searchString == null) {
			alert("Please select a search field and a search term!");
			return;
		}

		//var cbIsServer = (document.getElementById("cbIsServer") as HTMLInputElement);
		//var cbIsPortable = (document.getElementById("cbIsPortable") as HTMLInputElement);
		//var cbIsVirtual = (document.getElementById("cbIsVirtual") as HTMLInputElement);

		//var isServer = cbIsServer.checked ? this.selectedIsServer : "";
		//var isPortable = cbIsPortable.checked ? this.selectedIsPortable : "";
		//var isVirtual = cbIsVirtual.checked ? this.selectedIsVirtual : "";

		//var term = this.searchString + "|" + isServer + "," + isPortable + "," + isVirtual;
		var term = this.searchString + "|,,";

		this.showSpinner(true);

		// This triggers if a custom field is selected (based on ID of selectedField)
		if (this.selectedField.id > 4) {
			this.customFieldSearch = true;
			//console.log("customFieldSearch: ", this.customFieldSearch);
			/*console.log("Custom field searched");
			console.log("selectedField: ", this.selectedField.id, " ", this.selectedField.name);
			console.log("searchString:", this.searchString);*/
			this.apiService.searchCustomFields(this.selectedField.value, this.searchString).subscribe(res => {
				this.assets = res;
				this.showSpinner(false);
			}, error => {
				//console.log('Error occured!');
			}, () => {
				//console.log("Returned asset successfully");
				//console.log("this.assets (complete): ", this.assets);
			});
		}
		else {
			this.apiService.getSerialNumbers(this.selectedField.name, term).subscribe(res => {
				this.customFieldSearch = false;
				//console.log("customFieldSearch: ", this.customFieldSearch);
				this.assets = res;
				this.showSpinner(false);
			});
		}
	}

	private showSpinner(show: boolean) {
		this.searching = show;
		var btn = document.getElementById("searchButton");
		btn.style.display = !show ? "block" : "none";
	}
	

	checkValue(isChecked: boolean, txtBoxId: string, selectId: string) {
		var txtBox = document.getElementById(txtBoxId);
		txtBox.className = isChecked ? "form-control bg-white border-warning" : "form-control bg-light";

		var selectBox = document.getElementById(selectId);
		selectBox.className = isChecked ? "form-control bg-warning" : "form-control bg-light";

	}

	openModal(asset: any) {
		const modalRef = this.modalService.open(ModalContentComponent);
		modalRef.componentInstance.asset = asset;
		modalRef.result.then((result) => {
			if (result) {
				//console.log(result);
			}
		});
	}
}