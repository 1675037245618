import { Component, OnInit, HostListener, Directive } from '@angular/core';
import { SidebarComponent } from '../nav/sidebar/sidebar.component';

@Component({
	selector: 'app-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.css'],
	host: { class: 'myClass' }
})

export class MainComponent implements OnInit {

	constructor() { }

	ngOnInit() {
		
	}

	removeMenu() {
		SidebarComponent.prototype.removeMenu();
	}

}
