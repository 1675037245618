import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/ApiService.class';

@Component({
  selector: 'app-assetdetails',
  templateUrl: './assetdetails.component.html',
  styleUrls: ['./assetdetails.component.css']
})
export class AssetdetailsComponent implements OnInit {

    isLoaded: boolean;
    currentcomputer: any;
    serial: any;
    cid: string;

    constructor(private activatedRoute: ActivatedRoute, private apiService: ApiService) {
        this.isLoaded = false;

    }

  ngOnInit(): void {
      this.activatedRoute.params.subscribe(paramsId => {
          this.serial = paramsId.serial;
          this.cid = paramsId.cid;
          this.apiService.searchSerial(this.serial, this.cid).subscribe(res => {
              this.apiService.getSelfComputer(res[0], this.cid).subscribe(
                  r => {
                      this.currentcomputer = r;
                  });
          });
      });


  }

}
