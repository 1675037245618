import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../services/ApiService.class';
import { Computer, SlmObject, ObjectModel } from "../../classes/classes";
import { AppConfig } from '../../app.config';
import { SessionService } from '../../services/session.service';
import { NavbarComponent } from '../../nav/navbar/navbar.component';

@Component({
    selector: 'app-modal-content',
    providers: [ApiService],
    templateUrl: './modal-content.component.html',
    styleUrls: ['./modal-content.component.css']
})
export class ModalContentComponent implements OnInit {
    @Input() public asset;
    @Output() passEntry: EventEmitter<any> = new EventEmitter();
    sessionService: SessionService;
    currentCustomer: number;

    constructor(
        public activeModal: NgbActiveModal,
        private apiService: ApiService
    ) { }

    async ngOnInit() {
		this.sessionService = new SessionService();
        this.currentCustomer = NavbarComponent.currentCustomer;
	}

    closeModal() {
        this.activeModal.close(this.asset);
    }

    passBack() {
        try {
            let objModel = this.generateModel(this.asset);
            this.postToServer(objModel);
            this.closeModal();
        } catch (error) {
            console.error(error);
        }
    }

    generateModel(asset:any): ObjectModel {
		try {
            let objModel = new ObjectModel();
			objModel.CsvFolder = AppConfig.settings.apiSettings.fileShare;
			objModel.Serialnumber = asset.biosSerialNumber;
			objModel.SimObject = { objectid: "", objecttypeid: "", objectname: "", organization: "", properties: [] };
			objModel.CurrentComputer = asset == null ? new Computer() : asset;

			objModel.LifeCycleState = "In Use";
            objModel.OperationalState = "In Transit from customer";
			objModel.UpdatedBy = this.sessionService.getUserName();
            
            return objModel;

		} catch (e) {
			alert(e);
		}
	}

    postToServer(asset:ObjectModel): any {
        try {
            this.apiService.postAsset(asset);
        } catch (e) {
            alert(e);
        }
    }
}