import { Component, OnInit, HostListener } from '@angular/core';
import { SessionService } from "../../services/session.service";
import { Router } from '@angular/router';
import { ApiService } from '../../services/ApiService.class';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
	
	constructor(private session: SessionService, private router: Router, private apiService: ApiService) { }

	showSwap: boolean = false;
	customers: any;
	customerService: any;
	deskSearch: any;
	mobSearch: any;
	deskManage: any;
	mobManage: any;
	deskSwap: any;
	mobSwap: any;
	deskCreate: any;
	mobCreate: any;

	cid: string;
	currentVersion: string;
	currentVersionDate: Date;

	ngOnInit() {
		this.cid = this.apiService.cid;

		this.deskSearch = document.querySelector<HTMLElement>("#deskSearch");
		this.mobSearch = document.querySelector<HTMLElement>("#mobSearch");
		this.deskManage = document.querySelector<HTMLElement>("#deskManage");
		this.mobManage = document.querySelector<HTMLElement>("#mobManage");
		this.deskSwap = document.querySelector<HTMLElement>("#deskSwap");
		this.mobSwap = document.querySelector<HTMLElement>("#mobSwap");
		this.deskCreate = document.querySelector<HTMLElement>("#deskCreate");
		this.mobCreate = document.querySelector<HTMLElement>("#mobCreate");
		this.deskSearch.classList.add("active");
		this.mobSearch.classList.add("active");
		this.customers = this.session.getCustomers();
		this.customerService = this.session.getUserRole();
		this.apiService.getVersion().subscribe(res => {
			this.currentVersion = res.version;
			this.currentVersionDate = res.date;
		});

		if (this.cid == "1" || this.cid == "2") {
			if (this.customerService == 'default' || this.customerService == null) {
				this.deskManage.classList.add("hide");
				this.mobManage.classList.add("hide");
				this.deskSwap.classList.remove("hide");
				this.mobSwap.classList.remove("hide");
			}
			else if (this.customerService == 'admin') {
				this.deskSwap.classList.remove("hide");
				this.mobSwap.classList.remove("hide");
			}
			else if (this.customerService == 'customerservice') {
				this.deskCreate.classList.add("hide");
				this.mobCreate.classList.add("hide");
			}
		}

		this.onReload();
	}

	onReload() {
		switch (this.router.url) {
			case "/search":
				this.search();
				break;
			case "/freesearch":
				this.manage();
				break;
			case "/swap":
				this.swap();
				break;
			case "/create":
				this.create();
				break;
			default:
				this.search();
				break;
		}	
	}

	menuToggle() {
		let sidebarNav = document.querySelector<HTMLElement>('.sidebar-mobile');
		let sidebarToggle = document.querySelector<HTMLElement>('.sidebar-toggle');
		let navbarToggle = document.querySelector<HTMLElement>('.navbar-toggle');
		sidebarNav.classList.toggle("toggled");
		sidebarToggle.classList.toggle("toggled");
		navbarToggle.classList.toggle("toggled");
	}

	removeMenu() {
		let sidebarNav = document.querySelector<HTMLElement>('.sidebar-mobile');
		let sidebarToggle = document.querySelector<HTMLElement>('.sidebar-toggle');
		let navbarToggle = document.querySelector<HTMLElement>('.navbar-toggle');

		if (sidebarNav.classList.contains("toggled")) { sidebarNav.classList.remove("toggled");}
		if (sidebarToggle.classList.contains("toggled")) { sidebarToggle.classList.remove("toggled"); }
		if (navbarToggle.classList.contains("toggled")) { navbarToggle.classList.remove("toggled"); }
	}

	search() {
		this.deskSearch.classList.add("active");
		this.mobSearch.classList.add("active");
		this.deskManage.classList.remove("active");
		this.mobManage.classList.remove("active");
		this.deskSwap.classList.remove("active");
		this.mobSwap.classList.remove("active");
		this.deskCreate.classList.remove("active");
		this.mobCreate.classList.remove("active");
		this.removeMenu();
	}

	manage() {
		this.deskManage.classList.add("active");
		this.mobManage.classList.add("active");
		this.deskSwap.classList.remove("active");
		this.mobSwap.classList.remove("active");
		this.deskSearch.classList.remove("active");
		this.mobSearch.classList.remove("active");
		this.deskCreate.classList.remove("active");
		this.mobCreate.classList.remove("active");
		this.removeMenu();
	}

	swap() {
		this.deskSwap.classList.add("active");
		this.mobSwap.classList.add("active");
		this.deskManage.classList.remove("active");
		this.mobManage.classList.remove("active");
		this.deskSearch.classList.remove("active");
		this.mobSearch.classList.remove("active");
		this.deskCreate.classList.remove("active");
		this.mobCreate.classList.remove("active");
		this.removeMenu();
	}

	create() {
		this.deskCreate.classList.add("active");
		this.mobCreate.classList.add("active");
		this.deskSearch.classList.remove("active");
		this.mobSearch.classList.remove("active");
		this.deskManage.classList.remove("active");
		this.mobManage.classList.remove("active");
		this.deskSwap.classList.remove("active");
		this.mobSwap.classList.remove("active");
		this.removeMenu();
	}

	logout() {
		this.session.clearAll();
		this.customers = null;
		this.router.navigateByUrl('login');
	}

}
