import { Component, OnInit, Inject, NgZone, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser'
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { AppConfig } from '../../app.config';
import { Computer, SlmObject, ObjectModel } from "../../classes/classes";
import { ApiService } from '../../services/ApiService.class';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';
import { NavbarComponent } from '../../nav/navbar/navbar.component';
import { SidebarComponent } from '../../nav/sidebar/sidebar.component';
//import { CameraService } from "../../services/CameraService"; // Not implemented, needs more work
import { Html5Qrcode } from "html5-qrcode";
import { LoginPageComponent } from '../../login-page/login-page.component';
import { SessionService } from '../../services/session.service';


@Component({
	selector: "app-assetsearch",
	templateUrl: "./assetsearch.component.html",
	providers: [ApiService],
	styleUrls: ["./assetsearch.component.css"]
})
export class AssetsearchComponent implements OnInit {

	emptyPostRef: any;

	splitPurchaseDate: string;

	searchActive: boolean = false;

	locisCollapsed: boolean;
	statisCollapsed: boolean;
	opstatisCollapsed: boolean;
	locationSelected: boolean;

	toggleSerial: boolean = false;

	customerAssetName: string = "";
	productCategory: string = "";

	lifeCycleError: string = "";
	opStateError: string = "";

	collapseCustom: boolean = false;

	postedValues: boolean = false;

	endOfLease: Date;
	//lcState: any;
	//opState: any;
	//locState: any;
	model: SlmObject;
	purchaseDateExpired = false;
	validateOrderedError = false;
	validateCountryError = false;
	validateLocationError = false;
	validateVendorError = false;
	validateAssetOwnerError = false;
	validateCustomerNotesError = false;
	validateStockLocationError = false;
	searching = false;
	searchingloc = false;
	searchFailed = false;

	emptyPost = false;

	objModel = new ObjectModel();

	newValues = [];
	oldValues = [];
	changedFields = [];
	filteredLifecycles = [];
	filteredOpStates = [];
	stocklocation = [];
	stockFiltered = [];
	costCenter: any;
	filteredCostCenter: any;
	totalCostCenter: any;

	currentCustomer: any;

	purchaseExpiredText: string;

	ua = navigator.userAgent;
	deviceType: string = "";

	currentAssetCostCenter = null;
	currentStockLocation = null;
	//currentStockLocationName = null;
	useCountry = false;
	useLocation = false;
	useCustomerNotes = false;
	useOrderedFor = false;
	useVendorOrderNumber = false;
	useAssetOwnerTag = false;
	useStockLocation = false;
	orderedFor = null;
	vendorOrderNumber = null;
	assetOwnerTag = null;
	stockLocationFreeText = null;
	//orderedFormat: RegExp = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
	country = null;
	location = null;
	customerNotes = null;
	
	selectedLifecycle = null;
	selectedConfigLifeCycle = null;
	currentOperationalStates = null;
	selectedStockLocation = null;
	selectedOpstate = null;
	selectedCostCenter = null;
	
	hasLocation = null;
	useCostCenter = null;
	category = null;

	currentcomputer: any = null;
	currentcomputerCopy: any = null;
	cid: string;

	// camservice;
	// Camera related variables
	cameraId;
	scanning: boolean;
	html5QrCode;
	availableCameras;
	selectedCamera = null;
	scanField: string;
	cameraChosen: boolean = false;
	cameraRunning: boolean = false;

	lookup;
	computername;

	refDetect: ChangeDetectorRef;

	_http: HttpClient;
	_baseUrl: string;

	locreslist: Array<any>;

	stateConfig: any;

	lifecycles: any = [];

	opList: any = [];
	opstates: any = [];
	customerStateConfig: any;

	sessionService: SessionService;
	loginComponent: LoginPageComponent;

	async ngOnInit() {
		await this.fetchLocation();		
		this.cid = this.apiService.cid;
		this.isMobile();
		this.searchActive = false;
		await this.getCostCenter();
		await this.getCameras();
		this.sessionService = new SessionService();
		setTimeout(() => {
			this.getStateConfig();
		}, 500);
	}

	constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string, private apiService: ApiService,
		private zone: NgZone, ref: ChangeDetectorRef) {
		this._http = http;
		this._baseUrl = baseUrl;
		this.currentcomputer = new Computer();
		this.refDetect = ref;
		this.currentCustomer = NavbarComponent.currentCustomer;
	}

	formatter = (result: any) => result.objectname;
	computerformatter = (result: any) => result.biosSerialNumber;
	computerNameFormatter = (result: any) => result.Name;

	async getStateConfig() {
		this.customerStateConfig = JSON.parse(await this.sessionService.getCustomerStateConfig());
	}
	
	/** Checks if device is mobile or desktop. Device type is used by CameraService. */
	isMobile() {		
		if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(this.ua)) {
			return "tablet";
		}
		if (
			/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
				this.ua
			)
		) {
			this.deviceType = "mobile";
		}
		this.deviceType = "desktop";
	};

	/** Runs whenever the user clicks to toggle between serial number or computer name. */
	toggleSerialComputername() {
		if (this.toggleSerial) {
			this.toggleSerial = false;
			return;
		}
		if (!this.toggleSerial) {
			this.toggleSerial = true;
			return;
		}
	}

	removeAssettable() {
		const details = document.getElementById("computerdetails");
		const assetTable = document.getElementById("assettable");
		details.classList.remove("opened");
		assetTable.classList.remove("opened");
	}

	// This function runs whenever the user starts typing in the #lookup input field
	searchSerial = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			tap(() => this.searchActive = false),
			tap(() => this.searching = true),
			switchMap(term =>
				this.apiService.searchSerial(String(term)).pipe(
					tap(() => this.searchFailed = false),
					catchError(() => {
						this.searchFailed = true;
						return of([]);
					}))
			),
			tap(() => this.searching = false),
		);

	searchComputerName = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			tap(() => this.searchActive = false),
			tap(() => this.searching = true),
			switchMap(term =>
				this.apiService.searchComputerName(String(term)).pipe(
					tap(() => this.searchFailed = false),
					catchError(() => {
						this.searchFailed = true;
						return of([]);
					})
				)
			),
			tap(() => this.searching = false),
		);

	async getCameras() {
		Html5Qrcode.getCameras().then(devices => {
			/**
			 * devices would be an array of objects of type:
			 * { id: "id", label: "label" }
			 */

			if (devices && devices.length) {
				if (devices.length < 2) {
					this.cameraId = devices[0].id;
				}
				this.availableCameras = devices;
			}
		}).catch(err => {
			// handle err
		});
	}

	prepareScanning(field: string) {
		this.scanning = true;
		this.scanField = field;
		if (this.availableCameras.length < 2) {
			this.cameraChosen = true;
			this.startScanning();
		}
		else if (this.html5QrCode) {
			this.startScanning();
		}
	}

	cameraSelected(camera: any) {
		this.cameraChosen = true;
		try {
			if (this.availableCameras.length > 1 && this.html5QrCode.isScanning === true) {
				this.swapCamera();
				this.cameraId = camera;
				this.startScanning();
			}
			else {
				this.cameraId = camera;
				this.startScanning();
			}
		}
		catch {
			this.cameraId = camera;
			this.startScanning();
		}
	}

	startScanning() {
		this.cameraRunning = true;
		this.html5QrCode = new Html5Qrcode(/* element id */ "reader");
		this.lookup = document.getElementById("lookup");
		this.computername = document.getElementById("computername");
		const config = {
			fps: 10,
			qrbox: { width: 250, height: 250 },
			rememberLastUsedCamera: true
			// Only support camera scan type.
		};
		// If you want to prefer back camera

		const qrCodeSuccessCallback = (decodedText, decodedResult) => {
			/* handle success */
			if (this.scanField === "serialComputer") {
				if (this.toggleSerial === true) {
					//this.searchComputerName(decodedText);
					this.scanning = false;
					this.computername.value = decodedText;
					this.computername.dispatchEvent(new Event('input'));
					this.computername.focus();
					this.html5QrCode.stop().then((ignore) => {
						// QR Code scanning is stopped.
					}).catch((err) => {
						console.error(err);
					});
				}
				else {
					//this.searchSerial(decodedText);
					this.scanning = false;
					this.lookup.value = decodedText;
					this.lookup.dispatchEvent(new Event('input'));
					this.lookup.focus();
					this.html5QrCode.stop().then((ignore) => {
						//QR Code scanning is stopped.
					}).catch((err) => {
						console.error(err);
					});
				}
			}
		}

		if (this.deviceType == "mobile") {
			this.html5QrCode.start({ facingMode: "environment" });
		}
		else if (this.deviceType == "desktop") {
			this.html5QrCode.start({ deviceId: { exact: this.cameraId } }, config, qrCodeSuccessCallback);
		}

	}

	swapCamera() {
		try {
			if (this.html5QrCode.isScanning === true) {
				this.cameraRunning = false;
				this.html5QrCode.stop().then((ignore) => {
					// QR Code scanning is stopped.
				}).catch((err) => {
					// Stop failed, handle it.
				});
			}
		}
		catch (err) {
			this.cameraRunning = false;
		}
	}	

	cancelScan() {
		//const html5QrCode = new Html5Qrcode(/* element id */ "reader");
		try {
			if (this.html5QrCode.isScanning === true) {
				this.scanning = false;
				this.cameraRunning = false;
				this.html5QrCode.stop().then((ignore) => {
					// QR Code scanning is stopped.
				}).catch((err) => {
					// Stop failed, handle it.
				});
			}
			else {
				this.scanning = false;
				this.cameraRunning = false;
			}
		}
		catch (err) {
			this.cameraRunning = false;
		}
	}

	removeMenu() {
		SidebarComponent.prototype.removeMenu();
	}

	// Gets the StockLocations associated with the current customer ID.
	async fetchLocation() {
		this.apiService.getLocation().subscribe(res => {
			this.stocklocation = res;
		}, error => {
			console.error(error);
		});
	}

	// This pulls the selected customer's cost centers from the SQL database
	async getCostCenter() {
		//this.currentAssetCostCenter = "";
		this.useCostCenter = false;
		this.apiService.getCostCenter().subscribe(res => {
			if (res.costCenter != null) {
				this.costCenter = res.costCenter.split(",").slice();
			} else {
				this.costCenter = [];
			}
		}, error => {
			console.error(error);
		});
	}

	setCostCenter() {
		let customf = null;

		for (let i = 0; i < this.currentcomputer.customFields.length; i++) {
			if (this.currentcomputer.customFields[i].name == "Cost center") {
				// stock is set to the index of the element
				customf = i;
				break;
			}
		}

		if (customf != null) {
			this.currentAssetCostCenter = this.currentcomputer.customFields[customf].value;
			this.selectedCostCenter = this.currentAssetCostCenter;
			this.costCenter.push(" ");
		}

		/* if ((this.costCenter.length > 0) && (this.costCenter[0] != "")) {
			this.useCostCenter = true;
			let cost = null;
			for (let i = 0; i < this.costCenter.length; i++) {
				if (this.costCenter[i] == this.selectedCostCenter) {
					// stock is set to the index of the element
					cost = i;
					break;
				}
			}
			if (cost != null) {
				this.totalCostCenter = this.costCenter.slice();
				this.filteredCostCenter = this.costCenter.splice(cost, 1);
			}
		} */
	}

	/** This removes the lifecycle state matching that of currentcomputer and adds it to a separate array (to prevent duplicate entries in the dropdown). */
	filterLifeCycles() {
		if (this.currentcomputer.lifeCycleState != null && this.currentcomputer.lifeCycleState.toLowerCase() == "retired") {
			this.selectedConfigLifeCycle = this.customerStateConfig.find(l => l.acceptedLifeCycle.toLowerCase() == "retired");
			this.lifecycles = this.lifecycles.indexOf(this.lifecycles.find(o => o.acceptedLifeCycle.toLowerCase() == "retired"));
			this.selectedOpstate = this.currentcomputer.operationalState;
		}
		else {
			this.selectedConfigLifeCycle = this.customerStateConfig.find(l => l.acceptedLifeCycle.toLowerCase() == this.selectedLifecycle.toLowerCase());
			this.lifecycles = this.customerStateConfig.slice();
			
			let cycleFound = this.lifecycles.indexOf(this.lifecycles.find(o => o.acceptedLifeCycle.toLowerCase() == this.currentcomputer.lifeCycleState.toLowerCase()));	
			
			if (cycleFound) {
				let spliced = this.lifecycles.splice(cycleFound, 1);
			}
			this.selectedOpstate = this.currentcomputer.operationalState;
		}
	}

	/**
	 * Updates the states based on the selected lifecycle and operational state of the current computer.
	 */
	updateStates() {
		this.selectedConfigLifeCycle = this.selectedLifecycle ? this.customerStateConfig.find(l => l.acceptedLifeCycle.toLowerCase() == this.selectedLifecycle.toLowerCase()) : null;
		this.currentOperationalStates = this.selectedConfigLifeCycle.acceptedOperational.slice();
		let ops = this.currentOperationalStates.slice();

		let spliced = null;
		if (ops.find(o => o.toLowerCase() == this.currentcomputer.operationalState.toLowerCase())) {
			let index = ops.findIndex(o => o.toLowerCase() == this.currentcomputer.operationalState.toLowerCase());
			spliced = ops.splice(index, 1);
		}
		this.currentOperationalStates = ops.slice();

		if (this.selectedLifecycle.toLowerCase() == "ordered - awaiting receipt") {
			this.locationSelected = false;
		}
		else if (this.selectedLifecycle.toLowerCase() == "in stock") {
			this.locationSelected = true;
		}
		else if (this.selectedLifecycle.toLowerCase() == "in use") {
			this.locationSelected = false;
		}
		else if (this.selectedLifecycle.toLowerCase() == "returned for maintenance") {
			this.locationSelected = true;
		}
		else if (this.selectedLifecycle.toLowerCase() == "retired") {
			this.locationSelected = true;
		}
		else if (this.selectedLifecycle.toLowerCase() == "returned to supplier") {
			this.locationSelected = true;
		}
		else if (this.selectedLifecycle.toLowerCase() == "missing") {
			this.locationSelected = false;
		}

	}

	/** Only displays editable field for OrderedFor if the customer ID matches with 1 (DEMO), 3 (Storebrand), 23 (Systembolaget) or 24 (Aibel). */
	showOrderedFor() {
		if (["1", "3", "23", "24"].includes(this.cid)) {
			this.useOrderedFor = true;
			try {
				this.orderedFor = this.currentcomputer.orderedFor;		
			}
			catch (error) {
				this.orderedFor = "";
			}
		}
		else {
			this.useOrderedFor = false;
		}
	}

	/** Only displays editable field for VendorOrderNumber if the customer ID matches with 1 (DEMO) or 23 (Systembolaget). */
	showVendorOrderNumber() {
		if (["1","23"].includes(this.cid)) {
			this.useVendorOrderNumber = true;
			try {
				let isVendor = this.currentcomputer.customFields.find(o => o.name.toLowerCase() === 'vendor order number').value;
				if (isVendor) {
					this.vendorOrderNumber = isVendor;
				}
			}
			catch (error) {
				//this.vendorOrderNumber = "";
			}
		}
		else {
			this.useVendorOrderNumber = false;
		}
	}

	/** Only displays editable field for AssetOwnerTag if the customer ID matches with 1 (DEMO) or 24 (Systembolaget). */
	showAssetOwnerTag() {
		if (["1","24"].includes(this.cid)) {
			this.useAssetOwnerTag = true;
			try {
				let isAssetOwnerTag = this.currentcomputer.customFields.find(o => o.name.toLowerCase() === 'assetownertag').value;
				if (isAssetOwnerTag) {
					this.assetOwnerTag = isAssetOwnerTag;
				}
			}
			catch (error) {
				//this.assetOwnerTag = "";
			}
		}
		else {
			this.useAssetOwnerTag = false;
		}
	}

	/** Only displays editable field for AssetOwnerTag if the customer ID matches with 1 (DEMO) or 24 (Systembolaget). */
	showStockLocation() {
		if (["1", "24"].includes(this.cid)) {
			this.useStockLocation = true;
			try {
				let isStockLocation = this.currentcomputer.customFields.find(o => o.name.toLowerCase() === 'stocklocation').value;
				if (isStockLocation) {
					this.selectedStockLocation = isStockLocation;
				}
			}
			catch (error) {
				console.error(error);
			}

		}
	}

	/** Only displays Country and Location if the customer ID matches with 1 (DEMO) or 3 (Storebrand). */
	showCountry() {
		if (["1", "3"].includes(this.cid)) {
			this.useCountry = true;
			try {
				this.country = this.currentcomputer.customFields.find(o => o.name.toLowerCase() === 'country').value;
			} catch (error) {
				this.country = null;
			}
		}	
	}

	/** Only displays Country and Location if the customer ID matches with 1 (DEMO), 3 (Storebrand) or 24 (Systembolaget). */
	showLocation() {
		if (["1", "3", "24"].includes(this.cid)) {
			this.useLocation = true;
			try {
				this.location = this.currentcomputer.customFields.find(o => o.name.toLowerCase() === 'location').value;
			} catch (error) {
				this.location = null;
			}
		}	
	}

	/** Only displays editable field for Customer Notes if the customer ID matches with 1 (DEMO) or 24 (Systembolaget). */
	showCustomerNotes() {
		if (["1", "24"].includes(this.cid)) {
			this.useCustomerNotes = true;
			try {
				this.customerNotes = this.currentcomputer.customFields.find(o => o.name.toLowerCase() === 'customernotes').value;
			} catch (error) {
				this.customerNotes = null;
			}
		}
	}

	/** Removes any special characters in order to sanitize input. */
	validateInput(fieldInput, element) {
		let temp = fieldInput;
		fieldInput = fieldInput.replace(/[^\d\s\p{Ll},@.\\()/_-]/giu, " "); // Regex pattern that filters out all special characters except for ,@.\\()/-_
		let myInput: any = document.querySelector('input[name="' + element + '"]');
		if (temp != fieldInput) {
			myInput.value = fieldInput;
		}

		if (element == "orderedFor") {
			this.orderedFor = fieldInput;
			if (temp != fieldInput) {
				this.validateOrderedError = true;
			}
			else {
				this.validateOrderedError = false;
			}
		}

		else if (element == "country") {
			this.country = fieldInput;
			if (temp != fieldInput) {
				this.validateCountryError = true;
			}
			else {
				this.validateCountryError = false;
			}
		}

		else if (element == "location") {
			this.location = fieldInput;
			if (temp != fieldInput) {
				this.validateLocationError = true;
			}
			else {
				this.validateLocationError = false;
			}
		}

		else if (element == "vendorOrderNumber") {
			this.vendorOrderNumber = fieldInput;
			if (temp != fieldInput) {
				this.validateVendorError = true;
			}
			else {
				this.validateVendorError = false;
			}
		}
		
		else if (element == "assetOwnerTag") {
			this.assetOwnerTag = fieldInput;
			if (temp != fieldInput) {
				this.validateAssetOwnerError = true;
			}
			else {
				this.validateAssetOwnerError = false;
			}
		}
		
		else if (element == "customerNotes") {
			this.customerNotes = fieldInput;
			if (temp != fieldInput) {
				this.validateCustomerNotesError = true;
			}
			else {
				this.validateCustomerNotesError = false;
			}
		}

		else if (element == "stockLocation") {
			this.stockLocationFreeText = fieldInput;
			if (temp != fieldInput) {
				this.validateStockLocationError = true;
			}
			else {
				this.validateStockLocationError = false;
			}
		}
		
	}

	/** This function runs whenever the user selects an asset from the drop-down menu. */
	selectedItem(item) {
		this.cid = this.apiService.cid;
		this.apiService.getSelfComputer(item.item).subscribe(res => {
			this.currentcomputer = res;
		}, error => {
		}, () => {
			//this.getStateConfig();
			this.searchActive = true;
			this.collapseCustom = false;
			this.resetProperties();

			let computerFilteredLifeCycle = this.customerStateConfig.find(o => o.acceptedLifeCycle.toLowerCase() === this.currentcomputer.lifeCycleState.toLowerCase());
			if (computerFilteredLifeCycle) {
				this.currentcomputer.lifeCycleState = computerFilteredLifeCycle.acceptedLifeCycle;
			}
			let computerFilteredOperationalState = computerFilteredLifeCycle.acceptedOperational.find(o => o.toLowerCase() === this.currentcomputer.operationalState.toLowerCase());
			if (computerFilteredOperationalState) {
				this.currentcomputer.operationalState = computerFilteredOperationalState;
			}
			
			this.selectedStockLocation = this.currentcomputer.stockLocation;
			this.selectedLifecycle = this.currentcomputer.lifeCycleState;

			try {
				this.customerAssetName = this.currentcomputer.customFields.find(o => o.name.toLowerCase() == 'customer asset name').value;
			} catch (e) {
				//alert(e);
			}
			
			this.selectedConfigLifeCycle = this.customerStateConfig.find(o => o.acceptedLifeCycle.toLowerCase() == this.selectedLifecycle.toLowerCase());
			this.currentOperationalStates = this.selectedConfigLifeCycle.acceptedOperational.slice();
			
			this.filterLifeCycles();
			this.updateStates();
			this.setCostCenter();
			this.showOrderedFor();
			this.showVendorOrderNumber();
			this.showAssetOwnerTag();
			this.showStockLocation();
			this.showCountry();
			this.showCustomerNotes();
			this.showLocation();
			setTimeout(() => {
				this.generateModel();
			}, 300);		
		});

		const details = document.getElementById("computerdetails");
		const assetTable = document.getElementById("assettable");
		details.classList.add("opened");
		assetTable.classList.add("opened");
	}

	/** This assembles the object model, and postToServer() passes the object model to the ApiService.
	 * 
	 * ApiService then sends the object model to CsvWrite to create a CSV file.

	 * The CSV file is then placed in a folder and shortly after sent to MCS-SNOW11, where it's read by the inventory server and pushed into the database. */
	generateModel(): any {
		try {
			this.emptyPost = true;
			this.lifeCycleError = "";
			this.opStateError = "";
			this.currentCustomer = NavbarComponent.currentCustomer;

			this.objModel.CsvFolder = AppConfig.settings.apiSettings.fileShare;
			this.objModel.Serialnumber = this.currentcomputer.biosSerialNumber;
			this.objModel.SimObject = { objectid: "", objecttypeid: "", objectname: "", organization: "", properties: [] };
			this.objModel.CurrentComputer = this.currentcomputer == null ? new Computer() : this.currentcomputer;

			// Lifecycle state
			if (this.selectedLifecycle) {
				if (this.selectedLifecycle.name) { this.objModel.LifeCycleState = this.selectedLifecycle.name; }
				else { this.objModel.LifeCycleState = this.selectedLifecycle; }
			}
			else {
				this.lifeCycleError = "Please select a lifecycle state!";
			}
			
			// Operational state
			if (this.selectedOpstate != null) {
				let ops = this.selectedConfigLifeCycle.acceptedOperational.slice();
				if (this.selectedOpstate) {
					let ope = null;
					for (let i = 0; i < ops.length; i++) {
						if (ops[i].toLowerCase() == this.selectedOpstate.toLowerCase()) {
							// ope is set to the index of the element
							ope = i;
							break;
						}
					}
					if (ope != null) {
						this.objModel.OperationalState = this.selectedOpstate;
					}
					else {
						this.opStateError = this.selectedOpstate.toString() + " is not a valid operational state for the selected lifecycle state!";
					}					
				}
				else {
					let ope = null;
					for (let i = 0; i < this.opstates.length; i++) {
						if (this.opstates[i].name.toLowerCase() == this.selectedOpstate.toLowerCase()) {
							// ope is set to the index of the element
							ope = i;
							break;
						}
					}
					if (ope != null) {
						this.objModel.OperationalState = this.selectedOpstate;
					}
					else if (this.selectedOpstate == "" || this.selectedOpstate == " ") {
						this.opStateError = "Please select an operational state!";
					}
					else {
						this.opStateError = this.selectedOpstate.toString() + " is not a valid operational state for the selected lifecycle state!";
					}
					//return;
				}
			}
			else {
				this.opStateError = "Please select an operational state!";
			}

			 // Stocklocation
			if (this.stocklocation != null) {
				this.objModel.SimObject = { objectid: "", objecttypeid: "", objectname: "", organization: "", properties: [] };
				this.objModel.SimObject.objectname = this.selectedStockLocation;
				//this.selectedStockLocation = this.currentcomputer.stockLocation;
				/* let stock = null;
				for (let i = 0; i < this.stocklocation.length; i++) {
					if (this.stocklocation[i].objectname == this.selectedStockLocation) {
						// stock is set to the index of the element
						stock = i;
						break;
					}
				}
				if (stock != null) {
					if (this.stocklocation[stock] == "") {
						this.objModel.SimObject = { objectid: "", objecttypeid: "", objectname: "", organization: "", properties: [] };
					} else {
						this.objModel.SimObject = this.stocklocation[stock];
					}
				}
				else if (this.stockFiltered[0]) {
					if (this.selectedStockLocation == this.stockFiltered[0].objectname) {
						this.objModel.SimObject = this.stockFiltered[0];
					}
				} */
			} 

			// Cost center
			if (this.selectedCostCenter != null) {
				let cost = null;
	
				if (typeof this.costCenter !== 'string' && this.costCenter.length > 0) {
					for (let i = 0; i < this.costCenter.length; i++) {
						if (this.costCenter[i] == this.selectedCostCenter) {
							// cost is set to the index of the element
							cost = i;
							break;
						}
					}
				}

				if (cost) {
					this.objModel.SimObject.CostCenter = this.costCenter[cost];
				}
				else if (this.selectedCostCenter) {
					this.objModel.SimObject.CostCenter = this.selectedCostCenter;
				}
				else {
					this.objModel.SimObject.CostCenter = "";
				}
				
			}

			// Ordered for
			if (this.orderedFor != null) {
				this.validateInput(this.orderedFor, 'orderedFor');
				this.objModel.SimObject.OrderedFor = this.orderedFor;
			}

			// Vendor Order Number
			if (this.vendorOrderNumber != null && (this.vendorOrderNumber != this.objModel.SimObject.VendorOrderNumber)) {
				this.validateInput(this.vendorOrderNumber, 'vendorOrderNumber');
				this.objModel.SimObject.VendorOrderNumber = this.vendorOrderNumber;
			}

			// Asset Owner Tag
			if (this.assetOwnerTag != null && (this.assetOwnerTag != this.objModel.SimObject.AssetOwnerTag)) {
				this.validateInput(this.assetOwnerTag, 'assetOwnerTag');
				this.objModel.SimObject.AssetOwnerTag = this.assetOwnerTag;
			}

			// Customer Notes
			if (this.customerNotes != null && (this.customerNotes != this.objModel.CustomerNotes)) {
				this.validateInput(this.customerNotes, 'customerNotes');
				this.objModel.CustomerNotes = this.customerNotes;
			}

			// Country
			if (this.country != null && (this.country != this.objModel.SimObject.Country)) {
				this.validateInput(this.country, 'country');
				this.objModel.SimObject.Country = this.country;
			}
			
			// Location
			if (this.location != null && (this.location != this.objModel.SimObject.Location)) {
				this.validateInput(this.location, 'location');
				this.objModel.SimObject.Location = this.location;
			}

			this.objModel.UpdatedBy = this.sessionService.getUserName();
			
			this.changedFields.length = 0;
			this.oldValues.length = 0;
			this.newValues.length = 0;

			if (this.objModel.LifeCycleState != null) { this.compareFields('system', this.objModel.LifeCycleState, 'lifeCycleState'); }
			if (this.objModel.OperationalState != null) { this.compareFields('system', this.objModel.OperationalState, 'operationalState'); }
			if (this.objModel.SimObject.objectname != null) { this.compareFields('system', this.objModel.SimObject.objectname, 'stockLocation'); }
			if (this.objModel.SimObject.OrderedFor != null) { this.compareFields('system', this.objModel.SimObject.OrderedFor, 'orderedFor'); }

			if (this.objModel.SimObject.CostCenter != null) { this.compareFields('custom', this.objModel.SimObject.CostCenter, 'Cost center'); }
			if (this.objModel.SimObject.VendorOrderNumber != null ) { this.compareFields('custom', this.objModel.SimObject.VendorOrderNumber, 'Vendor Order Number'); }
			if (this.objModel.SimObject.AssetOwnerTag != null ) { this.compareFields('custom', this.objModel.SimObject.AssetOwnerTag, 'AssetOwnerTag'); }
			if (this.objModel.CustomerNotes != null ) { this.compareFields('custom', this.objModel.CustomerNotes, 'CustomerNotes'); }
			if (this.objModel.SimObject.Country != null) { this.compareFields('custom', this.objModel.SimObject.Country, 'Country'); }
			if (this.objModel.SimObject.Location != null) { this.compareFields('custom', this.objModel.SimObject.Location, 'Location'); }

			if (this.changedFields.length <= 0) {
				this.emptyPost = true;
			}			

		} catch (e) {
			alert(e);
		}
	}

	/** This resets all variables that might contain information about an asset, in order to prevent issues when selecting a different asset. */
	private resetProperties() {
		this.objModel = new ObjectModel();
		this.emptyPost = true;
		this.postedValues = false;
		this.changedFields.length = 0;
		this.oldValues.length = 0;
		this.newValues.length = 0;

		this.selectedConfigLifeCycle = null;
		this.currentOperationalStates = null;
		this.country = null;
		this.location = null;
		this.orderedFor = null;
		this.vendorOrderNumber = null;
		this.assetOwnerTag = null;
		this.selectedCostCenter = null;
		this.selectedStockLocation = null;
		this.selectedLifecycle = null;
		this.selectedOpstate = null;
		this.currentAssetCostCenter = null;
		this.customerNotes = null;
	}

	/** This function compares the values of the object model with the values of the current computer.
	 * 
	 * If the values differ, the field is added to the changedFields array, and the new and old values are added to the newValues and oldValues arrays respectively.
	 * 
	 * @parma valueType - The type of value that is being compared (system or custom).
	 * @param objField - The value of the object model.
	 * @param compField - The value of the current computer.
	 * */
	private compareFields(valueType:string, objField: string, compField:string) {
		if (objField != null || objField != undefined) {
			valueType = valueType.toLowerCase();
			let oldValue;

			this.postedValues = false;

			if (valueType === 'system') {
				oldValue = this.currentcomputer[compField] || "";
			}
				
			else if (valueType === 'custom') {
				let customValue = this.currentcomputer.customFields.find(o => o.name.toLowerCase() === compField.toLowerCase());
				if (customValue != null && customValue != undefined) {
					oldValue = customValue.value;
				}
				else {
					oldValue = "";
				}
			}
			
			if (objField != oldValue) {
				this.changedFields.push(compField);
				this.newValues.push(objField);
				this.oldValues.push(oldValue);
				this.emptyPost = false;
			}
		}
	}

	/** This function posts the object model to the ApiService.
	 * 
	 * NOTE: DO NOT REMOVE
	 * 
	 * This function might be tagged as not in use by your IDE because it's not being used in this component.ts file, but it is being used by assetsearch.component.html.
	 */
	private postToServer(model): any {
		this.apiService.postAsset(model);
	}

	/** This function is called when the user clicks the "Review changes" button. 
	 * 
	 * This function might be tagged as not in use by your IDE because it's not being used in this component.ts file, but it is being used by assetsearch.component.html.
	*/
	private readyToPost() {
		this.generateModel();
		this.postedValues = true;
	}

	/** This function is called when the user clicks the "Cancel" button. 
	 * 
	 * This function might be tagged as not in use by your IDE because it's not being used in this component.ts file, but it is being used by assetsearch.component.html.
	*/
	private cancelPost() {
		this.lifeCycleError = "";
		this.opStateError = "";
		this.postedValues = false;
	}

	toggleVisibility() {
		const details = document.getElementById('computerdetails');
		const main = document.getElementById('main');
	}

}

