import { HttpClient, HttpHeaders, HttpClientModule, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { ObjectModel } from '../classes/classes';
import { AppConfig } from '../app.config';
import { LoginResultModel } from '../classes/classes';
import { DataService } from "../services/data.service";
import { SessionService } from '../services/session.service';
import { mergeMap } from "rxjs/operators";
import { AuthenticationService } from './authentication.service';

/**
 * Service for making API calls.
 */
@Injectable()
export class ApiService {

	headers_object: HttpHeaders;
	baseUrl: string;
	httpOptions: { headers: HttpHeaders };
	cid: string;
	session: SessionService;

	constructor(private http: HttpClient, private sess: SessionService, private dataService: DataService, private authService: AuthenticationService, @Inject('BASE_URL') baseUrl: string) {
		this.baseUrl = baseUrl;
		this.session = sess;
		this.headers_object = new HttpHeaders();
		this.headers_object = this.headers_object.append('Content-Type', 'application/json; charset=utf-8');
		this.headers_object = this.headers_object.append("Accept", "application/json; charset=utf-8");
		this.headers_object = this.headers_object.append("Authorization", "Bearer " + this.session.getToken());
		this.httpOptions = {
			headers: this.headers_object
		};

		this.dataService.currentCustomerId.subscribe(cid => this.cid = cid);
	}

	/**
	 * Searches for locations based on the specified search term.
	 * @param term - The search term
	 * @returns {Observable<string>} Observable of the search results
	 */
	searchLocation(term: string): Observable<any> {
		if (term == '') {
			return of([]);
		}
		return this.http.get<any>(this.baseUrl + 'api/slm/customers/' + this.cid + '/searchlocations/' + term, this.httpOptions);
	}

	swapLog(logLevel: string, message: string, customerId: string, userName: string, assetId: string, timeStamp: string) {
		let logObject = { logLevel, message, customerId, userName, assetId, timeStamp };
		this.http.post<any>(this.baseUrl + 'api/log/swap/', logObject, this.httpOptions).subscribe(() => {
			console.log(logObject);
		}, error => console.error(error));
	}

	/** This function gets a list of all the objects registered on a customer (StockLocations in this instance) */
	getLocation() {
		return this.http.get<any>(this.baseUrl + 'api/slm/customers/' + this.cid + '/locations/', this.httpOptions);
	}

	/** This function gets a list of all cost centers for a given customer from SQL. */
	getCostCenter() {
		return this.http.get<any>(this.baseUrl + 'api/slm/costcenter/' + this.cid, this.httpOptions);
	}

	/**
	 * This function sends a request through CsvController to CsvWrite to create a CSV file based on the asset object.
	 * @param asset The asset object to be written to the CSV file.
	 */
	postAsset(asset: any) {
		this.http.post(this.baseUrl + 'api/csv/customers/' + this.cid + '/', asset, this.httpOptions).subscribe(() => {
			window.location.reload();
		}, error => console.error(error));
	}

	/**
	 * Endpoint for swapping old assets.
	 * @param oldAsset The asset object to be swapped out.
	 */
	postSwapOld(oldAsset: ObjectModel) {
		this.http.post(this.baseUrl + 'api/csv/customers/' + this.cid + '/swap/old', oldAsset, this.httpOptions).subscribe(() => {
		}, error => console.error(error));
	}

	/**
	 * Endpoint for swapping new assets.
	 * @param oldAsset The asset object to be swapped in.
	 */
	postSwapNew(newAsset: ObjectModel) {
		this.http.post(this.baseUrl + 'api/csv/customers/' + this.cid + '/swap/new', newAsset, this.httpOptions).subscribe(() => {
			window.location.reload();
		}, error => console.error(error));
	}

	/**
	 * This function sends a request to the SLM API to create a new asset.
	 * @param asset The asset object to be created.
	 */
	createAsset(asset: any) {
		this.http.post(this.baseUrl + 'api/snowxml/customers/' + this.cid + '/', asset, this.httpOptions).subscribe(() => {
			window.location.reload();
		}, error => console.error(error));
	}

	/**
	 * GET request to SLM API
	 * 
	 * Fetches a list of all assets that match with the given serial number (term).
	 * @param term - The search string
	 * @param cid - The customer ID
	 * @returns A list of assets matching the given search string */
	searchSerial(term: string, cid: string = ''): Observable<any> {
		if (term == '') {
			return of([]);
		}
		term = this.handleStartsWithS(term);
		let customerId = cid === '' ? this.cid : cid;
		return this.http.get<any>(this.baseUrl + 'api/slm/customers/' + customerId + '/searchassets/' + term, this.httpOptions);
	}

	getPortalSerial(cid: string, serial: string): Observable<any> {
		if (cid == '' || serial == '') {
			return of([]);
		}
		return this.http.get<any>(this.baseUrl + 'api/portal/' + cid + '/' + serial, this.httpOptions);
	}

	/** GET to PortalController
	 * 
	 * Fetches object mappings from customerobjectmapping.json via Config.cs
	 */
	getPortalConfig(): Observable<any> {
		return this.http.get<any>(this.baseUrl + 'api/portal/config', this.httpOptions);
	}

	/** GET to SlmController
	 * 
	 * This function queries SQL for a specific device and all associated fields.
	 * @param computer - The computer object
	 * @param cid - The customer ID
	 * @returns {Observable<any>} Observable of the search results
	 */
	getSelfComputer(computer: any, cid: string = ''): Observable<any> {
		let customerId = cid === '' ? this.cid : cid;
		return this.http.get<any>(this.baseUrl + 'api/slm/customers/' + customerId + '/assetdetailsweb/' + computer.id, this.httpOptions);
	}

	login(username: string, password: string): Observable<any> {
		let headers_object = new HttpHeaders();
		headers_object = headers_object.append('Content-Type', 'application/json; charset=utf-8');
		headers_object = headers_object.append("Accept", "application/json; charset=utf-8");
		headers_object = headers_object.append("Authorization", "Basic " + btoa(username + ":" + password));
		let httpOptions = {
			headers: headers_object
		};
		return this.http.post<any>(this.baseUrl + 'api/login', null, httpOptions);
	}

	/**
	 * POST to TokenController
	 * 
	 * Refreshes the access token.
	 * @returns An Observable that emits the refreshed access token.
	 */
	refreshAccessToken(): Observable<any> {
		return this.authService.refreshAccessToken();
	}

	/**
	 * Checks if a token is expired.
	 * @param token - The token to check.
	 * @returns True if the token is expired, false otherwise.
	 */
	isTokenExpired(token: string): boolean {
		return this.authService.isTokenExpired(token);
	}

	/** Retrieves a list of customers from the SLM API. */
	getCustomers(): Observable<any> {
		let headers_object = new HttpHeaders();
		headers_object = headers_object.append('Content-Type', 'application/json; charset=utf-8');
		headers_object = headers_object.append("Accept", "application/json; charset=utf-8");
		headers_object = headers_object.append("Authorization", "Bearer " + this.session.getToken());
		let httpOptions = {
			headers: headers_object
		};
		return this.http.get<any>(this.baseUrl + 'api/slm/customers/', httpOptions);
	}

	/**
	 * Retrieves a list of computers based on the specified search field and search term.
	 * @param searchField The field to search for (e.g., name, manufacturer, model, user, serial number).
	 * @param searchTerm The term to search for.
	 * @returns A collection of Computer objects that match the search criteria.
	*/
	getSerialNumbers(field: string, searchTerm: string): Observable<any> {
		searchTerm = this.handleStartsWithS(searchTerm);
		return this.http.get<any>(this.baseUrl + 'api/slm/customers/' + this.cid + '/serialsearch/' + field + '/' + searchTerm, this.httpOptions);
	}

	/** Gets customer state config from CustomerStateConfig.json. */
	getCustomerStateConfig() {
		return this.http.get<any>(this.baseUrl + 'api/slm/customers/' + this.cid + '/stateconfig', this.httpOptions);
	}

	/** Gets current version of Asset Manager from version.json */
	getVersion() {
		return this.http.get<any>(this.baseUrl + 'api/slm/version/', this.httpOptions);
	}

	private handleError(error: HttpErrorResponse) {
		if (error.status === 0) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong.
			console.error(
				`Backend returned code ${error.status}, ` +
				`body was: ${error.error}`);
		}
		// Return an observable with a user-facing error message.
		return throwError(
			'Something bad happened; please try again later.');
	}

	/** Takes a term as an input, checks if the first character is s/S and removes it */
	handleStartsWithS(term: string) {
		if (term.startsWith('s') || term.startsWith('S')) {
			term = term.substring(1);
		}
		return term;
	}

	/**
	 * Query SLM for devices that match with current search string
	 * @param field - The custom field to search through
	 * @param searchTerm - The search string
	 * @returns {Observable<any>} Observable of the search results
	 * */
	searchCustomFields(field: string, searchTerm: string): Observable<any> {
		searchTerm = this.handleStartsWithS(searchTerm);
		let regexField = field.replace(/20/g, " ");
		return this.http.get<any>(this.baseUrl + 'api/slm/customers/' + this.cid + '/searchcustomfields/' + regexField + '/' + searchTerm);
	}

	/**
	 * Searches for computers based on the provided search term and customer ID.
	 * @param searchTerm The term to search for in the computer names.
	 * @returns A collection of Computer objects that match the search criteria.
	*/
	searchComputerName(searchTerm: string): Observable<any> {
		return this.http.get<any>(this.baseUrl + 'api/slm/customers/' + this.cid + '/searchcomputername/' + searchTerm);
	}

}
