import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Injectable, Inject, EventEmitter, Output } from '@angular/core';
import { DataService } from "../../services/data.service";
import { SessionService } from "../../services/session.service";
import { Router } from '@angular/router';
import { ApiService } from "../../services/ApiService.class";

/** Class component for the navbar */
@Injectable()
@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

	constructor(private api: ApiService, private dataService: DataService, private session: SessionService, private router: Router) { }

	customers: any;
	customerid: any;
	static currentCustomer: any;
	setIntervalTimer = 10 * 1000; // 10 seconds in milliseconds
	interval = 15 * 60 * 1000; // 1 minute in milliseconds

	ngOnInit() {
		// Call the checkLoggedIn function initially with the firstCall parameter set to true
		// to prevent the user from being logged out immediately after logging in.
		this.checkLoggedIn(true);

		// Schedule the checkLoggedIn function to run every 15 minutes
		setInterval(() =>{
			this.checkLoggedIn();
		},  this.setIntervalTimer);

		this.dataService.currentCustomers.subscribe(customers => this.customers = customers);
		if (this.session.isLoggedIn()) {
			this.customerid = this.session.getCustomerId();
			this.customers = this.session.getCustomers();
			this.customers.sort((a, b) => a.name.localeCompare(b.name));
			this.api.getCustomerStateConfig().subscribe(res => {
				this.session.setCustomerStateConfig(res.acceptedStates);
			});
			NavbarComponent.currentCustomer = this.customers.find(o => o.id === this.customerid).name;
		}
	}

	/**
	 * Function to check if the user is logged in and refresh token every X minutes
	 * @param firstCall - boolean to check if this is the first time function is called
	*/
	checkLoggedIn(firstCall: boolean = false):void {
		let lastRefresh = new Date(this.session.getLastRefresh()).getTime();
		let refreshInterval = new Date().getTime() - (this.interval);
		let timeDifference = refreshInterval - lastRefresh;
		if (refreshInterval > lastRefresh) {
			let token = this.session.getToken();
			let tokenExpired = this.api.isTokenExpired(token);
			if (token) {
				if (tokenExpired) {
					this.logout();
				} else {
					this.api.refreshAccessToken().subscribe(
						r => {
							if (r && r.token && r.refreshToken) {
								this.session.setToken(r.token);
								this.session.setRefreshToken(r.refreshToken);
								this.session.setLastRefresh();
							}
						}),
						(err) => {
							console.error(err);
							this.logout();
						};
				}
			} else {
				this.logout();
			}
		}
		if (firstCall) return;
		if (!firstCall && (timeDifference > (this.interval * 2))) {
			this.logout();
		}
	}

	/** Toggle for the mobile menu, activates/deactivates when tapping on hamburger icon */
	menuToggle() {
		var sidebarNav = document.querySelector<HTMLElement>('.sidebar-mobile');
		var sidebarToggle = document.querySelector<HTMLElement>('.sidebar-toggle');
		var navbarToggle = document.querySelector<HTMLElement>('.navbar-toggle');
		sidebarNav.classList.toggle("toggled");
		sidebarToggle.classList.toggle("toggled");
		navbarToggle.classList.toggle("toggled");
	}

	/** Sends a request to DataService to change the customer ID in session storage.  */
	selectedItem() {
		this.dataService.setCustomerId(this.customerid);
		NavbarComponent.currentCustomer = this.customers.find(o => o.id === this.customerid).name;
		window.location.reload();
	}
	
	logout() {
		this.session.clearAll();
		this.customers = null;
		this.router.navigateByUrl('login');
	}

}
