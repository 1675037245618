import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './nav/sidebar/sidebar.component';
import { NavbarComponent } from './nav/navbar/navbar.component';
import { AppComponent } from './app.component';
import { AssetlistComponent } from './views/assetlist/assetlist.component';
import { AssetsearchComponent } from './views/assetsearch/assetsearch.component';
import { AssetFreeSearchComponent } from "./views/assetfreesearch/assetfreesearch.component";
import { AssetSwapComponent } from "./views/assetswap/assetswap.component";
import { AssetPortalComponent } from "./views/assetportal/assetportal.component";
import { AlertModule } from 'ngx-bootstrap';
import { CollapseModule } from 'ngx-bootstrap';
import { ApiService } from '../app/services/ApiService.class';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainComponent } from './main/main.component';
import { FooterComponent } from './ui/footer/footer.component';
import { HeaderComponent } from './ui/header/header.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { APP_INITIALIZER } from '@angular/core';
import { AppConfig } from './app.config';
import { DataService } from "../app/services/data.service";
import { Routes } from '@angular/router';
import { LoginPageModule } from './login-page/login-page.module';
import { LoginPageComponent } from './login-page/login-page.component';
import { NeedAuthGuard } from './auth.guard';
import { ModalContentComponent } from './views/modal-content/modal-content.component';
import { SitelayoutComponent } from './sitelayout/sitelayout.component';
import { ApplayoutComponent } from './applayout/applayout.component';
import { LandingpageComponent } from "./views/landingpage/landingpage.component";
import { AssetdetailsComponent } from "./views/assetdetails/assetdetails.component";
import { TableModule } from 'primeng/table';
import { RefreshTokenInterceptor } from '../app/services/token-interceptor.service';
import { AuthenticationService } from '../app/services/authentication.service';
//import { AssetresultComponent } from './views/assetresult/assetresult.component';
import { AssetCreateComponent } from './views/assetcreate/assetcreate.component';
import { environment } from '../environments/environment.prod';
import { NgxCsvParserModule } from 'ngx-csv-parser';


export function initializeApp(appConfig: AppConfig) {
	return () => appConfig.load();
}

const appRoutes: Routes = [
	{
		path: '', component: ApplayoutComponent,
		children: [
			{
				path: '', component: MainComponent,
				children: [
					{ path: '', component: AssetsearchComponent, canActivate: [NeedAuthGuard] },
					{ path: 'list', component: AssetlistComponent, canActivate: [NeedAuthGuard] },
					{ path: 'search', component: AssetsearchComponent, canActivate: [NeedAuthGuard] },
					{ path: 'freesearch', component: AssetFreeSearchComponent, canActivate: [NeedAuthGuard] },
					{ path: 'swap', component: AssetSwapComponent, canActivate: [NeedAuthGuard] },
					{ path: 'create', component: AssetCreateComponent, canActivate: [NeedAuthGuard] },
					//{ path: 'assetresult/:serial', component: AssetresultComponent, canActivate: [NeedAuthGuard] },
				 	//{ path: 'assetdetails/:cid/:serial', component: AssetdetailsComponent, canActivate: [NeedAuthGuard] }
				]
			}]
	},
	{
		path: '', component: SitelayoutComponent,
		children: [
			{ path: 'assetportal/:cid/:serial', component: AssetPortalComponent },
			{ path: 'login', component: LoginPageComponent },
			{ path: 'landingpage', component: LandingpageComponent }

		]
	},

];


@NgModule({
	declarations: [
		AppComponent,
		MainComponent,
		SidebarComponent,
		NavbarComponent,
		AssetsearchComponent,
		AssetlistComponent,
		AssetFreeSearchComponent,
		AssetSwapComponent,
		AssetCreateComponent,
		AssetPortalComponent,
		FooterComponent,
		HeaderComponent,
		ModalContentComponent,
		SitelayoutComponent,
		ApplayoutComponent,
		LandingpageComponent,
		AssetdetailsComponent,
		//AssetresultComponent
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
		AlertModule.forRoot(),
		CollapseModule.forRoot(),
		FontAwesomeModule,
		BrowserAnimationsModule,
		HttpClientModule,
		NgbModule,
		HttpClientXsrfModule.withOptions({ cookieName: 'XSRF-COOKIE' }),
		FormsModule,
		RouterModule.forRoot(appRoutes),
		LoginPageModule,
		TableModule,
		NgxCsvParserModule
	],
	entryComponents: [
		ModalContentComponent
	],
	providers: [AppConfig, NeedAuthGuard, ApiService, NavbarComponent, DataService, AuthenticationService,
		{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			deps: [AppConfig], multi: true
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: RefreshTokenInterceptor,
			multi: true
		},
		{
			provide: LOCALE_ID, useValue: 'no-nor'
		}
	],
	bootstrap: [AppComponent]

})
export class AppModule {
	constructor() {
		library.add(fas, far);
		//sessionStorage.setItem('cid', '3');
	}
}


