import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-applayout',
    templateUrl: './applayout.component.html',
    styleUrls: ['./applayout.component.css'],
})
export class ApplayoutComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
